.main_wrapper{
    display:flex;
    align-items: start;
}
.main_container{
    flex:1;
}
.sidebar_wrapper{
  height: 100vh;
  position:sticky;
  top:0;
  z-index: 2;
}