:root{
    ---primary-color:#6eb140;
}

.tab_container{
    display:flex; align-items:center;
justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,0.1)
}
.tabs_wrapper{
    display:flex; align-items:center;
    gap:20px;
}
.tab{
    padding:10px;
     border-bottom:2px solid transparent;
    cursor: pointer;
}
 .tab_active{
    border-color: var(---primary-color) !important;
    color:var(---primary-color)
}
.user_wrapper{
    display: flex;
    align-items: center
}
.table_avatar{
    border-radius:50%;
    margin-right:10px;
    border: 2px solid #fff;
    box-shadow:0 0 3px rgba(0,0,0,0.5)
}
.action_button{
    color:"#ff0000 !important";
    cursor:pointer;
    opacity:.7
}
.action_button:hover{
     color:"#ff0000 !important";
     opacity:1
}

.order_details_wrapper{
    background:rgba(0,0,0,0.1);
    position:fixed;
    width:79%;
    height:100%;
    top:0;
    right:-9999px;
    z-index:1;
    display: flex;
    justify-content:right;
    visibility: hidden;
    transition: right 0.3s ease-in-out;
    z-index:0;
}
.order_details_wrapper_open{
    right:0;
    z-index:5;
    visibility: visible;
   transition: right 0.3s ease-in-out;
}
.order_details_inner{
    background:#fff;
    height:100%;
    width:0%;
    right:0;
    position:relative;
    padding:40px;
    transition: width 0.3s ease-in-out;
    overflow-y: scroll;

}
.order_details_wrapper_open .order_details_inner{
     width:45%;
}
.order_cart_items{
    max-height: 600px;
    position:relative;
    overflow-y:scroll;
}

.ord_labels{
    font-weight: 500;
}
.close_button{
    cursor: pointer;
    position: absolute;
    top:20px;
    right:20px;
}

.caption{
    font-size: 12px;
}
.back_button:hover{
    background-color:""
}
.prod_sub_heading{
    margin-top:20px;
    margin-bottom:7px;
    font-size: 14px;
}
.image_upload{
  border: 1px dashed #B4C5DD;
  background-color:#F8FAFB;
  cursor:pointer;
}
.upload_label{
font-size: 12px;
}


.upload_img{
    border:1px dashed #B4C5DD;
    padding:5px;
    width: 100%;
}

.prod_hover{
    position:absolute;
    bottom:0;
    background-color:#6eb14030;
    width: 100%;
    left:0;
    height:0%;
    display:flex;
    align-items:center;
    justify-content: center;
    visibility:hidden;
        transition: all 0.3s ease-in-out;

}
.prod_edit_button{
    background-color:var(---primary-color);
    padding:10px;
    border-radius:50%;
    box-shadow: 0 0 12px #00000090;
    cursor:pointer;
}
.grocery_card:hover .prod_hover{
    visibility:visible;
    height:100%;
    overflow: hidden;
}

.min_card{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.stat_head{
    font-size:12px
}
.stat_num{
    font-size:25px
}

.ordr_nums{
    font-size: 35px;
    font-weight: 600;
}
.ordr_sta{
    font-size:13px
}
.top_sell_{
    font-size:12px; font-weight:500
}
.top_sell_cat{font-size: 11px;}

.card_label{
    font-weight: 500;
}