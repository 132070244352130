
body {
  font-family: "Poppins";
  background-color: #f6f8fa;
}
a {
  text-decoration: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
ul {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.ps-menuitem-root a {
  border-radius: 10px;
}
.ps-menuitem-root a.active,
.ps-menuitem-root a:hover {
  background-color: var(---primary-color) !important;
  color: #fff !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.upl .material-symbols-outlined {
  font-size: 30px !important;
  color: #636970;
}
.ctg .material-symbols-outlined {
  font-size: 22px !important;
  color: #636970;
}
.text-success {
  color: var(---primary-color) !important;
}
.btn-primary {
  background: var(---primary-color) !important;
  border-color: var(---primary-color) !important;
}
.btn-outline-primary {
  color: var(---primary-color) !important;
  border-color: var(---primary-color) !important;
}
.btn-outline-primary:hover {
  color: #fff !important;
  border-color: var(---primary-color) !important;
  background: var(---primary-color) !important;
}
.btn-outline-primary:hover svg {
  color: #fff !important;
}

.bg-primary{
  background-color: var(---primary-color) !important;
}
.g-filters {
  background-color: #b4c5dd40;
}

.hide_field {
  display: none;
}
input::placeholder,
select option {
  font-size: 13px;
  text-transform: capitalize;
}



.ps-open {
  background-color: transparent !important;
}
li.ps-menuitem-root {
  margin-bottom: 4px;
}
.tracker_card {
  position: relative;
}

.tracker_card::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 61%;
  top: 29px;
  left: 9px;
  background: var(---primary-color);
  opacity: 0.4;
}

.tracker_card:last-child:before {
  display: none;
}
.auth_form_container {
  width: 600px;
}
.dropdown {
  position: absolute;
  right: 23px;
  top: -9999px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}
.dropdown::before {
  content: "";
  position: absolute;
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-bottom: 12px solid #fff;
  top: -12px;
  right: 10px;
}
.adm_ {
  cursor: pointer;
  z-index: 3;
}

.dropdown.active {
  top: 74px;
  opacity: 1;
  visibility: visible;
}
.adm_ul li {
  display: block;
}
.adm_ul li a:hover,
.adm_ul li a.active {
  color: var(---primary-color) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
